
import { close, updateVar } from "./popups.js";

export default function() {
	var profileFormElement = $('.js-profile-form');

	var onClose = function() {
		close();
		$('#popupFade').hide();
	}

	var showForm = function() {
		$('.js-profile-form .js-profile-form form').fadeTo(200, 0, function() {
			$('.js-profile-form').hide(400, function() {
				$('.js-profile-form .m-profile--popup__submit').show('slow');
			});
		});
	}

	const hideLoader = () => {
		$('#popupLoader').hide();
		$('#popupFade').hide();
		updateVar('allowOverlay', true);
	}

	var onSubmit = function(e) {
		var button = $(this);
		var form = button.closest('form');
		$.ajax({
			url: '/api/currency/set?currencyCode=' + form[0].querySelectorAll('input[name="currencies"]:checked')[0].value + '&languageCode=' + form[0].querySelectorAll('input[name="languages"]:checked')[0].value,
			dataType: 'json',
			success: function success() {
				window.location.reload();
			},
			error: function error(e) {
				alert('Sorry! Something went wrong - please try again.');
				hideLoader();
			}
		});
	}

	profileFormElement.off('click', '.js-profile-form-submit').on('click', '.js-profile-form-submit', onSubmit);
	profileFormElement.off('click', '.js-profile-form-show').on('click', '.js-profile-form-show', showForm);
	profileFormElement.off('click', '.js-profile-form-close').on('click', '.js-profile-form-close', onClose);

}
